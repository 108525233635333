import { EmailComponent, useShowEmailSubscriptionComponent } from '@vp/email-subscription-component'
const HomePageEmailComponent = (props: any) => {
  const { accessToken, tenant, country, tracking, locale, placementId, trackingConfiguration } = props
  const { show, isAnonymous, discountInfo } = useShowEmailSubscriptionComponent(placementId, tenant, country, accessToken)
  return (
    <EmailComponent
      isAnonymous={isAnonymous}
      country={country}
      language={locale}
      tenant={tenant}
      tracking={tracking}
      show={show}
      discountInfo={discountInfo}
      placementData={trackingConfiguration}
      accessToken={accessToken}
    />
  )
}

export default HomePageEmailComponent
