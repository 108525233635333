import React from 'react'
import TrackingJS from '@vp/tracking'
import {
  BoundedContent,
  Box,
  ErrorBoundary,
  SWAN_STYLE_KEY_MAP
} from '@vp/swan'
import { countryCode } from './utils'
import { useSwanStyleKeys, useUserContextTracker, useIdentity } from '@vp/ubik-context'
import { TrackingProvider } from './hooks/useTrackingContext'
import HomePageEmailComponent from './components/EmailComponent'
import { ANALYTICS_METADATA } from './constants'

function App (props: any) {
  const { isSignedIn, identity } = useIdentity()
  let accessToken
  if (identity) {
    accessToken = identity.authorization.token
  }
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.banner,
  ])

  trackUsedContext(props)
  return (
    <TrackingProvider canonicalUrl='https://www.vistaprint.com'>
      <Box id='email'>
        {identity && (
          <ErrorBoundary>
            <BoundedContent paddingX='0'>
              <section>
                <HomePageEmailComponent
                  locale={props.locale}
                  country={countryCode(props.locale)}
                  tenant={props.tenant}
                  tracking={TrackingJS.getTracking()}
                  placementId={isSignedIn ? 'RCHP' : 'NVHP'}
                  accessToken={accessToken}
                  trackingConfiguration={ANALYTICS_METADATA}
                />
              </section>
            </BoundedContent>
          </ErrorBoundary>
        )}
      </Box>
    </TrackingProvider>
  )
}

function trackUsedContext (props: any) {
  const tracker = useUserContextTracker()
  if (props.experimentKey) {
    tracker({
      type: 'experiment',
      name: props.experimentKey,
      variation: props.variationKey
    })
  }
  if (props.decision) {
    tracker({
      type: 'treatment',
      name: props.decision
    })
  }
  if (props.merchandisingCampaign) {
    tracker({
      type: 'campaign',
      name: props.merchandisingCampaign
    })
  }
}

export default App
