import { useSwanStyleKeys, useUserContext, IdentityProvider, VPAuthConfig } from '@vp/ubik-context'

import App from './App'

export interface Props {
  greeting: string
}

export const createAuthConfig = (culture: string, isDevelopment: boolean): VPAuthConfig => ({
  culture,
  developmentMode: isDevelopment
})

export const Fragment = (props: any) => {
  const userContext = useUserContext()

  useSwanStyleKeys(['core'])
  return (
    <>
      <IdentityProvider auth={createAuthConfig(userContext.locale, false)}>
        <App {...props} />
      </IdentityProvider>
    </>
  )
}
