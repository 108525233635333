export const ANALYTICS_PAGE_NAME = 'Home Page'
export const ANALYTICS_PAGE_STAGE = 'Discover'
export const ANALYTICS_PAGE_SECTION = 'Home Page'
export const ANALYTICS_PAGE_DEPT = 'Home Page - V2'

export const ANALYTICS_METADATA = {
  pageName: ANALYTICS_PAGE_NAME,
  pageSection: ANALYTICS_PAGE_SECTION,
  pageStage: ANALYTICS_PAGE_STAGE,
  pageDept: ANALYTICS_PAGE_DEPT,
}
